<template>
  <div>
    <talmundo-switch
      id="disableManualInput"
      :label="$t('forms.date.disableManualInput')"
      v-model="disableManualInput"
      @change="changeType($event, 'disableManualInput')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion']),
    disableManualInput() {
      if (this.selectedQuestion && this.selectedQuestion.schema) {
        return this.selectedQuestion.schema.data.disableManualInput;
      }
      return true;
    }
  },
  methods: {
    changeType(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
